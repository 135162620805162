<template>
    <div class="customer-box">
        <div class="search-box">
            <div>
                <div class="search-one">
                    <p>
                        <Department
                            ref="department"
                            :departmentWidth="146"
                            :adminWidth="146"
                            :depSearch="btnP.depSearch"
                            :searchUser="btnP.searchUser"
                            @searchData="searchData"
                        ></Department>
                    
                        <span>查询</span>

                        <el-input
                            size="small"
                            style="width: 200px"
                            placeholder="公司名称、域名"
                            v-model.trim="searchVal.name"
                            clearable
                        >
                        </el-input>
                        <el-button
                            @click="
                                (pagesize = 10), (currentPage = 1), getData()
                            "
                            type="primary"
                            size="small"
                            icon="el-icon-search"
                        ></el-button>
                    </p>
                </div>
            </div>
        </div>
        <div class="table-title">
            <p>
                
            </p>
            <span>
                <button @click="onHistory()" class="cancel">
                    <i class="el-icon-plus" style="font-size: 12px"></i>
                    导入历史
                </button>
                <button @click="onImport()" class="cancel">
                    <i class="el-icon-plus" style="font-size: 12px"></i>
                    导入计划
                </button>
            </span>
        </div>
        <div class="table">
            <Table ref="table" @totalNub="totalNub" @getData="getData" />
        </div>
        <div class="page-box">
            <el-pagination
                v-if="isPage"
                @current-change="handleCurrentChange"
                @size-change="handleSizeChange"
                :current-page.sync="currentPage"
                :page-size="pagesize"
                :page-sizes="[10, 20, 50]"
                layout="total,sizes,prev, pager, next, jumper"
                :total="total"
            ></el-pagination>
        </div>
        
        <!-- 导入 -->
        <el-dialog
            :visible.sync="dialogImport"
            width="560px"
            :before-close="handleImport"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    导入计划
                </div>
            </template>
            <Import ref="import" @close="handleImport" />
        </el-dialog>
    </div>
</template>

<script>
import Import from '../import/import.vue';
import Department from '../../../components/DepartmentCus';
import Table from './table.vue';
import { departmentList } from '@/api/admin/framework/framework.js';
export default {
    components: {
        Import,
        Table,
        Department,
    },
    data() {
        return {
            searchVal: {
                name: '',
                departmentId: '',
                adminId: '',
            },
            btnP: {},
            pagesize: 10,
            total: 0,
            currentPage: 1,
            isPage: false,
            dialogImport: false,
            dialogChoice: false,
            dialogDelete: false,
            
        };
    },

    mounted() {
        if (
            sessionStorage.getItem('pageData') &&
            JSON.parse(sessionStorage.getItem('pageData'))
        ) {
            this.currentPage = JSON.parse(
                sessionStorage.getItem('pageData')
            ).pageNum;
            this.pagesize = JSON.parse(
                sessionStorage.getItem('pageData')
            ).pageSize;
            this.searchVal = JSON.parse(
                sessionStorage.getItem('pageData')
            ).param;
            this.$refs.department.$data.departmentId =
                JSON.parse(sessionStorage.getItem('pageData')).param
                    .departmentId || undefined;
            this.$refs.department.$data.adminId =
                JSON.parse(sessionStorage.getItem('pageData')).param.adminId ||
                '';
            sessionStorage.removeItem('pageData');
            this.isPage = true;
        } else {
            this.$refs.department.$data.departmentId =
                this.searchVal.departmentId || undefined;
            this.$refs.department.$data.adminId = this.searchVal.adminId || '';
            this.isPage = true;
        }

        this.getBtn();
        this.customerSearch();
    },
    methods: {
        customerSearch() {
            let data = {
                param: {},
            };
            if (!this.btnP.depSearch) {
                data.param.departmentId =
                    sessionStorage.getItem('departmentId');
            } else {
                data.param.departmentId = this.searchVal.departmentId;
            }
            if (this.btnP.searchUser) {
                data.param.adminId = this.searchVal.adminId;
            } else {
                if (!this.btnP.depSearch) {
                    data.param.adminId = sessionStorage.getItem('adminId');
                }
            }
        },
        // 获取按钮权限
        getBtn() {
            var btnArr = JSON.parse(sessionStorage.getItem('isButArr'));
            let btn = {};
            for (let i in btnArr) {
                btn['' + btnArr[i].route] = true;
            }
            this.btnP = btn;

            sessionStorage.setItem('isButArrHistory',JSON.stringify(btn));

            if (btn.depSearch) {
                this.getDempData();
                this.$refs.department.getUserList(
                    sessionStorage.getItem('departmentId')
                );
            }
            if (!btn.depSearch && btn.searchUser) {
                this.searchVal.adminId = sessionStorage.getItem('adminId');
                this.$refs.department.getUserList(
                    sessionStorage.getItem('departmentId')
                );
            }
            this.getData();
        },
        getDempData() {
            let data = {
                param: {},
            };
            departmentList(data).then((res) => {
                this.dempData = res.data;
                this.$refs.department.getData(this.btnP, res.data);
            });
        },
        searchData(data) {
            this.pagesize = 10;
            this.pageNum = 1;
            this.searchVal.departmentId = data.departmentId;
            this.searchVal.adminId = data.adminId;
            this.getData();
        },
        getData() {
            let data = {
                param: {
                    name: this.searchVal.name,
                },
                pageNum: this.currentPage,
                pageSize: this.pagesize,
            };
            if (!this.btnP.depSearch) {
                data.param.departmentId =
                    sessionStorage.getItem('departmentId');
            } else {
                data.param.departmentId = this.searchVal.departmentId;
            }
            if (this.btnP.searchUser) {
                data.param.adminId = this.searchVal.adminId;
            } else {
                if (!this.btnP.depSearch) {
                    data.param.adminId = sessionStorage.getItem('adminId');
                }
            }
            
            
            this.$refs.table.getData(data, this.btnP);
            this.customerSearch();
        },
        totalNub(i) {
            this.total = i;
        },
        // 分页
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getData();
        },
        handleSizeChange(val) {
            this.pagesize = val;
            this.getData();
        },
        
        // 导入
        onImport() {
            this.dialogImport = true;
        },
        handleImport() {
            this.dialogImport = false;
            this.getData();
        },

        // 导入历史
        onHistory() {
            this.$router.push({
                path: '/customerRecordHistory',
            });
        },
        
        // 关闭抽屉
        handleClose() {
            this.getData();
            this.tableRow = {};
            this.$choiceLabel.commit('emptyData');
        },
    },
    destroyed() {
        this.$searchLabel.commit('emptyData');
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.customer-box {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.search-box {
    width: 100%;

    display: flex;
    > div:first-child {
        flex: 1;
        background: #fff;
        border-radius: 4px;
    }
    .add_cus_num {
        width: 300px;
        margin-left: 10px;
        background-color: #fff;
        display: flex;
        align-items: center;
        i {
            display: block;
            width: 64px;
            height: 64px;
            background: rgba(253, 157, 56, 0.21);
            border-radius: 32px;
            text-align: center;
            line-height: 64px;
            font-size: 30px;
            color: #ffa802;
            margin-left: 16px;
        }
        p {
            display: flex;
            padding-left: 13px;
            flex-direction: column;
            flex: 1;
            span {
                font-size: 16px;
                font-weight: 600;
                margin: 0;
            }
            span:nth-child(2) {
                font-size: 26px;
                margin-top: 6px;
            }
        }
        button {
            font-size: 12px;
            width: 56px;
            height: 20px;
            border-radius: 13px;
            border: 1px solid #2370eb;
            background-color: #fff;
            cursor: pointer;
            margin-right: 15px;
            color: #2370eb;
            margin-top: 19px;
        }
    }
    span {
        font-size: 12px;
        font-weight: 600;
        color: #333333;
        margin: 0 16px;
    }
    .search-one {
        padding: 10px 0 12px 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .left {
            button {
                background: #2370eb;
                border-radius: 2px;
                border-color: #2370eb;
            }
        }
        .right {
            background: #2370eb;
            border-radius: 2px;
            border-color: #2370eb;
            margin-right: 16px;
        }
    }
    .search-two {
        display: flex;
        p {
            padding-bottom: 10px;
        }
    }
}
.table-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    padding: 12px 12px 12px 15px;
    .add {
        border-color: #d9d9d9;
        color: #333333;
    }
    p {
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        line-height: 20px;
        span {
            display: inline-block;
            padding: 1px 11px;
            border-radius: 20px;
            font-size: 13px;
            color: #666666;
            border: 1px solid #f0f0f0;
            cursor: pointer;
        }
        span + span {
            margin-left: 12px;
        }
        .active {
            background: #e9f2ff;
            color: #2370eb;
            position: relative;
        }
        .active:after {
            content: '';
            position: absolute;
            bottom: -13px;
            left: 50%;
            transform: translateX(-50%);
            width: 32px;
            height: 2px;
            background: #2370eb;
        }
    }
    button {
        font-size: 12px;
        padding: 0 7px 0 12px;
        height: 24px;
        background: #ffffff;
        border-radius: 2px;
        border: 1px solid #2370eb;
        cursor: pointer;
        color: #2370eb;
        margin-left: 16px;
        background: #fff;
        color: #333;
        border-color: #d9d9d9;
    }

    button:hover {
        background: #2370eb;
        color: #ffffff;
        border-color: #2370eb;
    }
    .cancel:hover {
        background: #fff;
        color: #333;
        border-color: #d9d9d9;
    }
}
.table {
    flex: 1;
}
.page-box {
    background: #fff;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.labelList {
    display: inline-block;
    .cancels {
        position: absolute;
        display: block;
        width: 0;
        height: 0;
        color: #fff;
        border-top: 10px solid rgba(255, 255, 255, 0);
        border-right: 10px solid rgba(255, 255, 255, 0);
        border-bottom: 10px solid rgba(255, 255, 255, 0);
        border-left: 10px solid yellow;
        -webkit-transform: rotate(45deg); /* for Chrome || Safari */
        -moz-transform: rotate(45deg); /* for Firefox */
        -ms-transform: rotate(45deg); /* for IE */
        -o-transform: rotate(45deg);
        right: -26px;
        bottom: -10px;
        .el-icon-close {
            color: #fff;
            position: absolute;
            font-size: 8px;
            left: -28px;
            top: -6px;
            -webkit-transform: rotate(45deg); /* for Chrome || Safari */
            -moz-transform: rotate(45deg); /* for Firefox */
            -ms-transform: rotate(45deg); /* for IE */
            -o-transform: rotate(45deg);
        }
    }
    p {
        position: relative;
        display: inline-block;
        padding: 2px 8px;
        padding-bottom: 2px !important;
        border-radius: 2px;
        margin-top: 3px;
        border: 1px solid #2370eb;
        margin-left: 16px;
        text-align: center;
        font-size: 14px;
    }
    .blue {
        color: #2370eb;
        border-color: #2370eb;
        .cancels {
            border-left: 10px solid #2370eb;
        }
    }
    .yellow {
        color: #fa8c15;
        border-color: #fa8c15;
        .cancels {
            border-left: 10px solid #fa8c15;
        }
    }
}
</style>
