<template>
    <div class="Import">
        <div style="padding: 20px 50px 150px 50px" v-if="!isSuccess">
            <div style="padding: 20px 0">
                <el-button
                    @click="onTemplateUrl()"
                    type="primary"
                    size="small"
                    style="
                        border-color: #2370eb;
                        background: #fff;
                        color: #2370eb;
                    "
                    >下载模板</el-button
                >
            </div>
            <div style="flex: 1">
                <el-upload
                    :on-progress="progress"
                    :action="action"
                    class="avatar-uploader"
                    :on-success="onUploadData"
                    :before-upload="beforeAvatarUploads"
                    multiple
                    :limit="1"
                    ref="businessLicense"
                    accept=".xls,.xlsx"
                    :before-remove="beforeRemove"
                >
                    <el-button type="text " style="color: #2370eb"
                        >点击上传</el-button
                    >
                </el-upload>
                
            </div>
        </div>
        <div v-if="isSuccess" class="Success">
            <p>
                <i class="el-icon-circle-check"></i>
            </p>
            <p>信息导入已完成，共{{ returnData.total }}条信息</p>
            <p>成功导入{{ returnData.successTotal }}条</p>
            <p>失败{{ returnData.failTotal }}条</p>
            <el-button
                @click="onDownload(returnData)"
                size="small"
                type="primary"
                style="color: #2370eb; border-color: #2370eb; background: none"
                >下载导入结果文档</el-button
            >
        </div>
        <div class="but">
            <el-button
                round
                type="primary"
                size="mini"
                style="
                    background: none;
                    border-color: #d5d6df;
                    color: #666666;
                    margin-left: auto;
                "
                @click="onClose"
                >取消</el-button
            >
            <el-button
                round
                type="primary"
                style="
                    background: #2370eb;
                    border-color: #2370eb;
                    color: #fff;
                    margin-right: 30px;
                "
                size="mini"
                @click="onSubmit"
                :loading="loading"
                >确定</el-button
            >
        </div>
    </div>
</template>
<script>
import { customerRecordImport } from '@/api/customer/customer.js';
export default {
    data() {
        return {
            loading: false,
            isSuccess: false,
            action: this.$url.url() + '/file/local/upload',
            returnData: {},
            searchVal: {},
            userType: sessionStorage.getItem('userType'),
            originalAttachmentName: "",
        };
    },
    mounted() {
    },
    methods: {
        // 上传资料
        onUploadData(response) {
            this.loading = false;
            if (response.code == 200) {
                this.fileName = response.data.newFileName;
            } else {
                this.$message.error();
                ('上传失败');
            }
        },
        
        beforeRemove() {
            this.fileName = '';
            this.originalAttachmentName = "";
        },
        // 校验
        beforeAvatarUploads(file) {
            if (this.fileName) {
                this.$message.error('仅上传一个附件');
                return false;
            }
            this.originalAttachmentName = file.name;
            return true;
        },
        
        progress() {
            this.loading = true;
        },
        onSubmit() {
            if (!this.isSuccess) {
                if (!this.fileName) {
                    this.$message.error('请先上传文件');
                } else {
                    let data = {
                        param: {
                            fileName: this.fileName,
                            originalAttachmentName: this.originalAttachmentName,
                        },
                    };
                    customerRecordImport(data).then((res) => {
                        // console.log(res);
                        if (res.code == 200) {
                            this.$message.success('上传成功');
                            this.returnData = res.data;
                            this.isSuccess = true;
                        }
                    });
                }
            } else {
                this.onClose();
            }
        },
        // 下载模板
        onTemplateUrl() {
            window.open(
                this.$url.templateUrl() + '/excel/model/plan_model.xlsx',
                '_blank'
            );
        },
        // 下载导入结果
        onDownload(data) {
            window.open(
                this.$url.templateUrl() + '/excel/' + data.resultFileName,
                '_blank'
            );
        },
        onClose() {
            this.$emit('close');
            this.isSuccess = false;
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.Success {
    text-align: center;
    padding: 50px 0 150px 0;
    p {
        line-height: 30px;
        i {
            font-size: 50px;
            color: #2370eb;
        }
    }
}
.but {
    width: 100%;
    height: 48px;
    border-top: 1px solid #e5e5e5;
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
        width: 72px;
    }
}
</style>
